@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }


  :root {
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }
 
  .dark {
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

.ant-layout-sider-trigger {
  background: #242832 !important;
}
.ant-layout-sider-trigger:hover {
  background: #14161c !important;
}

.ant-btn-primary {
  background: #1677ff !important;
}

.ant-btn-primary:disabled {
  background: #93c0ff !important;
  color: white !important;
}

.ant-btn-primary:disabled:hover {
  background: #93c0ff !important;
  color: white !important;
}

.ant-btn-primary:hover {
  background: #0653bf !important;
}

.ant-switch {
  background: rgba(0, 0, 0, 0.25);
}

mapbox-address-autofill {
  width: 100% !important;
}

[class$="--ResultsAttribution"] {
  display: none;
}

.phoneInput::placeholder {
  color: #9ca3af !important;
}

.phoneInput:hover {
  border-color: #d1d5db !important;
}

.hyper-elastic .sui-search-box__submit {
  font-family: inherit;
  cursor: pointer;
  background-color: transparent;
  background: none;
  background-image: none;
  display: inline-flex;
  align-items: center;
  column-gap: 0.375rem;
  border-radius: 0.375rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: rgb(17, 24, 39);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  margin: 0;
}

.hyper-elastic .sui-search-box__submit:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  background: none;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hyper-elastic form {
  width: 60%;
}
@media (max-width: 768px) {
  .hyper-elastic form {
    width: 100%;
  }
}

.hyper-elastic .sui-search-box {
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
}

.hyper-elastic .sui-search-box__text-input {
  appearance: none;
  background-color: #fff;
  border-color: transparent;
  padding-right: 0.75rem;
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  border-width: 1px;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  color: rgb(17, 24, 39);
  font-size: 0.875rem;
  line-height: 1.5rem;
  --tw-ring-inset: inset;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hyper-elastic .sui-search-box__text-input:focus {
  outline: none;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  border-color: #2563eb;
  box-shadow: none;
}

.hyper-elastic .sui-select__control {
  border-color: rgb(209 213 219);
  border-radius: 0.375rem;
}

.hyper-elastic .sui-select__single-value {
  color: rgb(17, 24, 39);
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
}

.hyper-elastic .sui-select__dropdown-indicator svg {
  scale: 0.75;
}

.hyper-elastic .sui-search-box__autocomplete-container {
  padding: 6px 12px;
}

.hyper-elastic .sui-search-box__autocomplete-container div {
  padding: 6px 12px;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: rgb(17, 24, 39);
}

.hyper-elastic .sui-search-box__autocomplete-container div[aria-selected="true"] {
  background: #eaeaea;
  color: rgb(17, 24, 39);
}
